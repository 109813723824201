<template>
  <fragment>
    <hero-header page-name="" title="Általános díjszabás" subtitle=""/>
    <v-container class="my-10">
      <v-row class="my-10">
        <v-col cols="12" class="text-center">
          <template>
            <div  class="mb-6">
              <p class="headline primary--text text--darken-3 mb-0">
                A javítások végleges költségéről, bevizsgálást követően, hivatalos árajánlatot adunk.<br>
                Elutasított árajánlat esetén fizetendő díjaink.<br>
                Bevizsgálási díj: bruttó 8000 Ft<br>
                Logisztikai díj: bruttó 6750 Ft<br>
              </p>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </fragment>
</template>

<script>
import heroHeader from '../components/titles/HeroTitle.vue';

export default {
  name: 'generalCosts',
  components: {
    heroHeader,
  },
  data() {
    return {
      foo: 'bar',
    };
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
